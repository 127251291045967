import axiosInstance from "@/api/axiosInstance";
import { checkWindow } from "@/lib/functions/_helpers.lib";
import { userData ,blogData} from "@/types/common.type";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { destroyCookie } from "nookies";
import { userSliceData } from "../interfaces/interfaces";

const initialState: userSliceData = {
  isLoggedIn: false,
  userData: null,
  blogData: null,
};

// export const loginUser = createAsyncThunk(
//   "/loginUser",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await axiosInstance.post(
//         "/admin/user/signin",
//         data
//       );
//       return res.data;
//     } catch (error) {
//       return rejectWithValue([]);
//     }
//   }
// );

export const userSlice = createSlice({
  name: "userSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoginData: (state, { payload }: { payload: userData }) => {
      // state.email
      state.userData = payload;
      state.isLoggedIn = true;
    },

    setaddblog: (state, { payload }: { payload: blogData }) => {
      // state.email
      state.blogData = payload;
      state.isLoggedIn = true;
    },
    checkLoggedInServer: (state, { payload }) => {
      state.isLoggedIn = payload?.hasToken;
      state.userData = payload?.user;
    },
    // logout: (state) => {
    //   state.isLoggedIn = false;
    //   state.userData = null;
    //   destroyCookie(null, "token");
    //   destroyCookie(null, "user");
    // }
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;

      destroyCookie(null, "token", { path: "/" });
      if (checkWindow()) {
        window.location.href = "/login";
      }
    },
  },
  extraReducers: {},
});

export const { setLoginData, checkLoggedInServer, logout, setaddblog } =
  userSlice.actions;

export default userSlice.reducer;
